import React from "react";
import { policies } from "../policy/data/_policy";
import PolicyPage from "../policy/_PolicyPage";

export default function PrivacyPolicyPage() {
	return (
		<PolicyPage
			title="Privacy Policy"
			content={policies}
      withTableOfContents
      pageName="privacy-policy"
		/>
	);
}
